table {
  border-radius: 5px;
  background-color: rgba(66, 66, 66, 1);
  border-spacing: 0;

  &.wide {
    min-width: 50%;
  }
  thead {
    background-color: #3c3c3c
  }

  tbody {
    tr {
      &:hover {
        td {
          background-color: rgba(81, 81, 81, 1);
        }
      }
    }
  }

  tr {
    th {
      text-align: left;
      font-weight: bold;
    }

    td,
    th {
      border-top: 1px solid rgba(81, 81, 81, 1);

      padding: 8px;

      &.numeric,
      &.date {
        text-align: right;
      }

      &:first-child {
        padding-left: 16px;
      }
      &:last-child {
        padding-right: 16px;
      }
    }

    &:first-child {
      td,
      th {
        border-top: none;
      }
    }
  }

  &.compact {
    padding: 4px;

    thead {
      tr {
        td,
        th {
          padding: 0px;
        }
      }
    }
    tr {
      td,
      th {
        padding: 4px;

        &:first-child {
          padding-left: 12px;
        }
        &:last-child {
          padding-right: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  table.wide {
    width: 100%;
  }
}
