.input-group {
  position: relative;
  padding: 8px;
}

label {
  display: block;
  color: white;
  font-size: 16px;
}

input,
select {
  padding: 8px;

  background: transparent;
  outline: none;
  border: none;

  color: white;
  font-size: 16px;
  position: relative;

  &[type="datetime-local"] {
    padding-top: 6px;
    padding-bottom: 5px;
  }

  option {
    background: rgba(81, 81, 81, 1);
    color: white;
  }
}

input:not([type="date"]):not([type="checkbox"]):not([type="radio"]),
select {
  border-bottom: 2px solid rgba(255, 255, 255, 0.7);
}

input:not([type="date"]):not([type="checkbox"]):not([type="radio"]):focus,
select:focus {
  border-bottom: 2px solid rgba(255, 255, 255, 1);
}

::-webkit-calendar-picker-indicator {
  cursor: pointer;
  background: url(https://cdn3.iconfinder.com/data/icons/linecons-free-vector-icons-pack/32/calendar-16.png) center/80% no-repeat;
  filter: invert(1);
  opacity: 0.8;
}

::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}