#nprogress .bar {
  @apply h-1;
}

#nprogress .bar {
  @apply bg-light-gray;
}

#nprogress .spinner-icon {
  @apply border-t-light-gray border-l-light-gray;
}
