@import "../styles/table.scss";
@import "../styles/inputs.scss";
@import "../styles/ace.scss";
@import "../fonts/fonts.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  border: none;
  color-scheme: dark;
}

body {
  padding: 0;
  margin: 0;
  min-height: 100dvh;
  min-width: 100%;
  background-color: rgba(50, 50, 50, 1);
  color: white;
}

html {
  background-color: #f1f5f9;
  scroll-behavior: smooth;
  scroll-padding-top: 5rem;
  overflow-x: hidden;
  text-size-adjust: none;

}