@font-face {
  font-family: "Bankgothic";
  src: url("./BankGothic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tahoma-bold";
  src: url("./tahoma-bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cascadia Mono';
  src: url('/fonts/CascadiaMono.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}